.collapse-container {
  transition: 0.3s ease-in-out;

  &.open {
    max-height: 1000px;
    opacity: 1;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
}
