$slideZIndex: 1;

// https://github.com/akiran/react-slick/issues/1167
// Fix issue with slick-slider's "fade" animations where last slide gets stacked over other active slides
// and shows wrong content
.slick-slide {
  z-index: $slideZIndex;
}

.slick-active {
  z-index: $slideZIndex + 1;
}
